/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import {
    AvailableLookupConsumer,
    ButtonElement,
    DatePickerElement,
    InputElement,
    ISelectRenderedOption,
    PrimaryText,
    SelectElement,
    useCptCodes,
    UtilHelper,
    VALIDATION_CONFIG,
} from "nirvana-react-elements"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import moment from "moment-timezone"
import { usaStates } from "typed-usa-states"

import { useAppSelector } from "../../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../../store/selectors/selectedPracticeRole.selector"
import { useAppDispatch } from "../../../../store/appDispatch.hook"
import { TOOLTIPS_CONFIG } from "../../../../config/tooltips.config"
import { checkerSelector } from "../../../../store/selectors/checker.selector"
import { COVERAGE_CONFIG } from "../../../../config/coverage.config"
import { checkerRunMedicaidChecks } from "../../../../store/thunks/checker.thunks"
import { checkerSetRunningState } from "../../../../store/slices/checker.slice"
import { CoverageCheckerRunningState } from "../../../../config/checker.config"
import { ROUTES_CONFIG } from "../../../../config/routes.config"
import { GENERAL_CONFIG } from "../../../../config/general.config"

import lockIcon from "../../../../assets/images/icons/lock-dark.svg"

export const MedicaidCheckerFormComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        watch,
        register,
        unregister,
        getValues,
        trigger,
    } = useForm()

    const dataWatcher = watch()

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const { medicaidChecks: stateMedicaidChecks } =
        useAppSelector(checkerSelector)

    const { availableCptCodes } = useCptCodes(
        AvailableLookupConsumer.coveragePortal,
        selectedPracticeRole?.availableModalities
    )

    const [maxDob] = useState<Date>(new Date())
    const [minNextAppointmentDate] = useState<Date>(new Date())

    const formDefaultValues = useMemo<
        Partial<IMedicaidCheckerInputData>
    >(() => {
        const firstCheckInState = stateMedicaidChecks[0]

        return {
            firstName: firstCheckInState?.inputData.firstName,
            lastName: firstCheckInState?.inputData.lastName,
            dob: firstCheckInState?.inputData.dob,
            state: firstCheckInState?.inputData.state,
            cptCode: firstCheckInState?.inputData.cptCode,
            memberId: firstCheckInState?.inputData.memberId,
            customerPatientType:
                firstCheckInState?.inputData.customerPatientType,
            customerPatientId: firstCheckInState?.inputData.customerPatientId,
            customerPatientNextAppointmentDate:
                firstCheckInState?.inputData.customerPatientNextAppointmentDate,
        }
    }, [stateMedicaidChecks[0]])

    // For selects with control prop need to set value manually in form
    useEffect(() => {
        formDefaultValues.state && setValue("state", formDefaultValues.state)

        formDefaultValues.cptCode &&
            setValue("cptCode", formDefaultValues.cptCode)
    }, [formDefaultValues.state, formDefaultValues.cptCode])

    // Retrigger member ID validation when state changes
    useEffect(() => {
        trigger("memberId")
    }, [dataWatcher.state, dataWatcher.memberId])

    const availableStates = useMemo<ISelectRenderedOption[]>(() => {
        return usaStates.map(state => {
            return {
                value: state.abbreviation,
                displayValue: state.name,
            }
        })
    }, [usaStates])

    const onSubmit = (data: any) => {
        if (!selectedPracticeRole) {
            return
        }

        dispatch(
            checkerRunMedicaidChecks({
                payload: [
                    {
                        ...data,

                        // Need to convert moment dates to string
                        dob: UtilHelper.dateToMysqlFormat(data.dob.toDate()),

                        customerPatientNextAppointmentDate:
                            data.customerPatientNextAppointmentDate
                                ? UtilHelper.dateToMysqlFormat(
                                      data.customerPatientNextAppointmentDate.toDate()
                                  )
                                : undefined,
                    } as IMedicaidCheckerInputData,
                ],
                practice: selectedPracticeRole.practice,

                onSuccess: () => {
                    dispatch(
                        checkerSetRunningState(
                            CoverageCheckerRunningState.RESULTS_MANUAL
                        )
                    )

                    // Add random part to the url, so we can safely catch browser back button then
                    // And if back button is clicked from showing results -> it will initiate "run new checks"
                    // Acts as history.push
                    navigate(
                        `${ROUTES_CONFIG.coverageCheckerUrl}?${
                            GENERAL_CONFIG.urlSearchParamsKeys.results
                        }=${new Date().getTime()}`
                    )
                },
            })
        )
    }

    const isMemberIdRequired = (): boolean => {
        return COVERAGE_CONFIG.medicaidStateRequiringMemberID.includes(
            getValues("state")
        )
    }

    const memberIdValidation = async () => {
        const memberId = getValues("memberId")

        return (
            !!memberId ||
            (isMemberIdRequired()
                ? "Member ID is required for this state"
                : true)
        )
    }

    return (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            <PrimaryText typography="h3">Run an eligibility check</PrimaryText>

            <PrimaryText className="mt-24px" size={20} lineHeight={24}>
                Enter the patient’s basic information to get started
            </PrimaryText>

            <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                <div
                    className="
                        flex mt-44px
                        md:block
                    "
                >
                    <InputElement
                        className="
                            mr-24px flex-1
                            md:mr-0px
                        "
                        label="Member First Name"
                        name="firstName"
                        tooltip={TOOLTIPS_CONFIG.calculate.firstName}
                        reactHookControl={control}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            maxLength: VALIDATION_CONFIG.maxLength,
                        }}
                        reactHookErrors={errors}
                        defaultValue={formDefaultValues.firstName}
                        isLabelStatic
                    />

                    <InputElement
                        className="
                            flex-1
                            md:mt-44px
                        "
                        label="Member Last Name"
                        name="lastName"
                        tooltip={TOOLTIPS_CONFIG.calculate.lastName}
                        reactHookControl={control}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            maxLength: VALIDATION_CONFIG.maxLength,
                        }}
                        reactHookErrors={errors}
                        defaultValue={formDefaultValues.lastName}
                        isLabelStatic
                    />
                </div>

                <div
                    className="
                        flex mt-44px
                        md:block
                    "
                >
                    <DatePickerElement
                        className="
                            mr-24px w-240px!
                            md:mr-0px md:w-full!
                        "
                        name="dob"
                        label="Date of Birth"
                        defaultValue={
                            formDefaultValues.dob
                                ? moment(formDefaultValues.dob)
                                : undefined
                        }
                        reactHookFormValidations={{
                            required: VALIDATION_CONFIG.required,
                        }}
                        shouldValidate
                        reactHookFormErrors={errors}
                        reactHookFormRegister={register}
                        reactHookFormUnregister={unregister}
                        reactHookFormSet={setValue}
                        maxDate={maxDob}
                        isLabelStatic
                    />

                    <InputElement
                        className="
                            flex-1
                            md:mt-44px
                        "
                        label="Payer"
                        name="payer"
                        defaultValue="Medicaid"
                        inputSuffix={<img src={lockIcon} alt="lock" />}
                        disabled
                        isLabelStatic
                    />
                </div>

                <div
                    className="
                        flex mt-44px
                        md:block
                    "
                >
                    <SelectElement
                        className="
                            mr-24px flex-1 flex-shrink-0
                            md:mr-0px
                        "
                        label="Patient State"
                        placeholder="Select one"
                        name="state"
                        reactHookFormRegister={register}
                        reactHookFormSet={setValue}
                        reactHookFormErrors={errors}
                        shouldValidate
                        defaultValue={formDefaultValues.state}
                        validations={{
                            required: VALIDATION_CONFIG.required,
                        }}
                        renderedOptions={availableStates}
                    />

                    <InputElement
                        className="
                            mr-24px flex-1 flex-shrink-0
                            md:mr-0px md:mt-44px
                        "
                        label={`Member ID${
                            !isMemberIdRequired() ? " (optional)" : ""
                        }`}
                        name="memberId"
                        reactHookErrors={errors}
                        reactHookControl={control}
                        reactHookValidations={{
                            maxLength: VALIDATION_CONFIG.maxLength,
                            validate: memberIdValidation,
                        }}
                        defaultValue={formDefaultValues.memberId}
                        isLabelStatic
                    />

                    <SelectElement
                        className="
                            flex-1 flex-shrink-0
                            md:mt-44px
                        "
                        label="CPT Code (optional)"
                        placeholder="Select one"
                        name="cptCode"
                        externalValue={dataWatcher.cptCode}
                        reactHookFormControl={control}
                        defaultValue={formDefaultValues.cptCode}
                        reactHookFormErrors={errors}
                        renderedOptions={availableCptCodes}
                        onSelected={cptCode => {
                            setValue("cptCode", cptCode, {
                                shouldValidate: true,
                            })
                        }}
                    />
                </div>

                <div
                    className="
                        flex mt-44px
                        md:block
                    "
                >
                    <SelectElement
                        className="
                            mr-24px flex-1 flex-shrink-0
                            md:mr-0px
                        "
                        label="Patient Type (optional)"
                        name="customerPatientType"
                        allowClear={true}
                        reactHookFormRegister={register}
                        reactHookFormSet={setValue}
                        reactHookFormErrors={errors}
                        renderedOptions={Object.values(
                            COVERAGE_CONFIG.selectRenderedPatientTypes
                        )}
                        defaultValue={
                            formDefaultValues.customerPatientType
                                ? COVERAGE_CONFIG.selectRenderedPatientTypes[
                                      formDefaultValues.customerPatientType
                                  ].displayValue
                                : undefined
                        }
                    />

                    <InputElement
                        className="
                            mr-24px flex-1 flex-shrink-0
                            md:mr-0px md:mt-44px
                        "
                        label="Patient ID (optional)"
                        name="customerPatientId"
                        reactHookControl={control}
                        reactHookValidations={{
                            maxLength: VALIDATION_CONFIG.maxLength,
                        }}
                        reactHookErrors={errors}
                        defaultValue={formDefaultValues.customerPatientId}
                        isLabelStatic
                    />

                    <DatePickerElement
                        className="
                            flex-1 flex-shrink-0
                            md:mt-44px
                        "
                        name="customerPatientNextAppointmentDate"
                        label="Next Appointment Date (optional)"
                        defaultValue={
                            formDefaultValues.customerPatientNextAppointmentDate
                                ? moment(
                                      formDefaultValues.customerPatientNextAppointmentDate
                                  )
                                : undefined
                        }
                        reactHookFormErrors={errors}
                        reactHookFormRegister={register}
                        reactHookFormSet={setValue}
                        minDate={minNextAppointmentDate}
                        isLabelStatic
                        allowClear
                    />
                </div>

                <div className="mt-24px flex justify-end">
                    <ButtonElement
                        label="Continue"
                        type="primary"
                        htmlType="submit"
                    />
                </div>
            </form>
        </div>
    )
}
