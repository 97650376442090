import {
    AvailableCoveragePortalPermission,
    PermissionsExistenceLogicalOperator,
} from "./rolesPermissions.config"
import {
    FEATURE_FLAGS_CONFIG,
    FeatureFlag,
    FeatureFlagsExistenceLogicalOperator,
} from "./featureFlags.config"
import { ROUTES_CONFIG } from "./routes.config"

export const MENU_CONFIG = {
    mainMenu: [
        {
            label: "Coverage Checker",
            url: ROUTES_CONFIG.coverageCheckerUrl,
            featureFlag: FEATURE_FLAGS_CONFIG.checkerFeatureFlags,
            featureFlagExistenceOperator:
                FeatureFlagsExistenceLogicalOperator.OR,
        },
        {
            label: "Reports",
            url: ROUTES_CONFIG.reportsUrl,
            submenu: [
                {
                    label: "General",
                    url: ROUTES_CONFIG.reportsUrl,
                    neededPermissions: [
                        AvailableCoveragePortalPermission.viewHistoricalCoverageChecks,
                    ],
                    featureFlag: FEATURE_FLAGS_CONFIG.reportsFeatureFlags,
                    featureFlagExistenceOperator:
                        FeatureFlagsExistenceLogicalOperator.OR,
                },
                {
                    label: "Medicaid",
                    url: ROUTES_CONFIG.medicaidReportsUrl,
                    neededPermissions: [
                        AvailableCoveragePortalPermission.viewHistoricalCoverageChecks,
                    ],
                    featureFlag:
                        FEATURE_FLAGS_CONFIG.medicaidReportsFeatureFlags,
                    featureFlagExistenceOperator:
                        FeatureFlagsExistenceLogicalOperator.OR,
                },
            ],
        },
        {
            label: "Search",
            url: ROUTES_CONFIG.searchUrl,
            neededPermissions: [
                AvailableCoveragePortalPermission.viewHistoricalCoverageChecks,
            ],
            featureFlag: FeatureFlag.ONE_VERIFY_SEARCH,
        },
        {
            label: "Overrides",
            url: ROUTES_CONFIG.overridesUrl,
            neededPermissions: [
                AvailableCoveragePortalPermission.overrideCoverageData,
            ],
            featureFlag: FeatureFlag.ONE_VERIFY_OVERRIDES,
        },
        {
            label: "Resets",
            url: ROUTES_CONFIG.planYearResetsUrl,
            neededPermissions: [
                AvailableCoveragePortalPermission.viewPlanYearResets,
            ],
            featureFlag: FeatureFlag.ONE_VERIFY_PLAN_YEAR_RESETS,
        },
        {
            label: "Organization",
            url: ROUTES_CONFIG.organizationUrl,
        },
        {
            label: "Support",
            url: ROUTES_CONFIG.supportUrl,
        },
    ] as IMenuItem[],

    profileAvailableTabs: [
        {
            key: "account",
            label: "Account Details",
        },
    ] as IProfileAvailableTab[],

    organizationTabsHiddenKeys: [
        "billerGroups",
    ] as OrganizationAvailableTabKey[],

    organizationAvailableTabs: [
        {
            key: "information",
            label: "Organization",
        },
        {
            key: "users",
            label: "Users",
            neededPermissions: [
                AvailableCoveragePortalPermission.managePracticeUsers,
            ],
        },
        {
            key: "billerGroups",
            label: "Biller Groups",
            neededPermissions: [
                AvailableCoveragePortalPermission.manageBillerGroups,
            ],
            featureFlag: FeatureFlag.ONE_VERIFY_BILLER_GROUPS,
        },
        {
            key: "payers",
            label: "Payer Rates",
            neededPermissions: [
                AvailableCoveragePortalPermission.readPayersConfiguration,
                AvailableCoveragePortalPermission.managePayersConfiguration,
            ],
            neededPermissionsLogicalOperator:
                PermissionsExistenceLogicalOperator.OR,
        },
    ] as IOrganizationAvailableTab[],
}
