import React, { useEffect, useState } from "react"
import { PrimaryText } from "nirvana-react-elements"
import { Link, useLocation } from "react-router-dom"
import useOnclickOutside from "react-cool-onclickoutside"

export const MenuDropdownElement: React.FunctionComponent<
    IMenuDropdownProps
> = props => {
    const containerRef = useOnclickOutside(() => {
        setIsSubmenuOpen(false)
    })

    const location = useLocation()

    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
    const [isActive, setIsActive] = useState(false)

    const toggleSubmenu = () => {
        setIsSubmenuOpen(current => !current)
    }

    useEffect(() => {
        const active = props.items.some((item: IMenuItem) => {
            return item.url === location.pathname
        })

        setIsActive(active)
    }, [location.pathname, props.items])

    return (
        <div
            className={`
                relative
                ${props.className}
            `}
            ref={containerRef}
        >
            <div className="relative cursor-pointer" onClick={toggleSubmenu}>
                <PrimaryText typography={isActive ? "textBold" : "text"}>
                    {props.label}
                </PrimaryText>
            </div>

            {isSubmenuOpen && (
                <div
                    className="
                        absolute w-200px z-100 bg-brand-offWhite top-30px left-0
                        rounded-8px border border-solid border-brand-warmShadow
                        overflow-hidden
                    "
                >
                    <div>
                        {props.items.map((item: IMenuItem, index: number) => {
                            const isActive = item.url === location.pathname

                            return (
                                <Link
                                    to={item.url}
                                    key={index}
                                    onClick={toggleSubmenu}
                                    className={`
                                        no-underline!
                                        ${isActive ? "pointer-events-none" : ""}
                                    `}
                                >
                                    <PrimaryText
                                        className={`
                                            py-8px px-16px
                                            ${
                                                isActive
                                                    ? "cursor-default bg-brand-lilacLight"
                                                    : "cursor-pointer bg-brand-white"
                                            }
                                        `}
                                        typography={
                                            isActive ? "textBold" : "text"
                                        }
                                    >
                                        {item.label}
                                    </PrimaryText>
                                </Link>
                            )
                        })}
                    </div>
                </div>
            )}
        </div>
    )
}
