/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"

import {
    CoverageCheckerMode,
    CoverageCheckerRunningState,
} from "../../../../config/checker.config"
import { useAppSelector } from "../../../../store/selectors/app.selector"
import { useAppDispatch } from "../../../../store/appDispatch.hook"
import { checkerSelector } from "../../../../store/selectors/checker.selector"
import { checkerSetRunningState } from "../../../../store/slices/checker.slice"
import { CheckerService } from "../../../../services/checker.service"
import { CoverageResultComponent } from "../../result/coverageResult.component"
import { PayerCoverageCheckNetwork } from "../../../../config/coverage.config"

export const MedicaidCheckerResultsComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const dispatch = useAppDispatch()
    const checkerState = useAppSelector(checkerSelector)

    const mappedMedicaidChecks = useMemo<
        IContinuousMonitoringCoverageCheck[]
    >(() => {
        // Wrong data in state
        if (!checkerState.medicaidChecks.length) {
            dispatch(
                checkerSetRunningState(
                    checkerState.checkerMode === CoverageCheckerMode.MANUAL
                        ? CoverageCheckerRunningState.INPUT_MANUAL
                        : CoverageCheckerRunningState.INPUT_CSV
                )
            )

            return []
        }

        return checkerState.medicaidChecks.map(check => {
            return {
                inputData:
                    CheckerService.mapMedicaidCheckDataToContinuousMonitoringInputData(
                        check
                    ),
                result: {
                    // Medicaid is always in network
                    [PayerCoverageCheckNetwork.OUT]: null,
                    [PayerCoverageCheckNetwork.IN]: check.result,
                },
            }
        })
    }, [])

    return mappedMedicaidChecks && checkerState.intakeCheck.result ? (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            {mappedMedicaidChecks.map((check, index) => {
                return check.result ? (
                    <CoverageResultComponent
                        key={index}
                        inputData={check.inputData}
                        results={check.result}
                    />
                ) : null
            })}
        </div>
    ) : null
}
