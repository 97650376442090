/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"

import { useAppSelector } from "../../../../store/selectors/app.selector"
import { checkerSelector } from "../../../../store/selectors/checker.selector"
import { CoverageCheckerRunningState } from "../../../../config/checker.config"
import { MedicaidCheckerFormComponent } from "./medicaidCheckerForm.component"
import { MedicaidCheckerResultsComponent } from "./medicaidCheckerResults.component"

export const MedicaidCheckerComponent: React.FunctionComponent<{
    className?: string
}> = props => {
    const checkerState = useAppSelector(checkerSelector)

    const isResultsView = useMemo<boolean>(() => {
        return (
            checkerState.runningState ===
            CoverageCheckerRunningState.RESULTS_MANUAL
        )
    }, [checkerState.runningState])

    const getContentBasedOnRunningState = (): JSX.Element | null => {
        switch (checkerState.runningState) {
            case CoverageCheckerRunningState.INPUT_MANUAL:
                return <MedicaidCheckerFormComponent className="mt-24px" />

            case CoverageCheckerRunningState.RESULTS_MANUAL:
                return <MedicaidCheckerResultsComponent />

            default:
                return null
        }
    }

    return (
        <div
            className={`
                ${props.className}
                relative
                ${
                    !isResultsView
                        ? "max-w-800px p-16px rounded-8px bg-brand-warmLight025"
                        : ""
                }
            `}
        >
            {getContentBasedOnRunningState()}
        </div>
    )
}
