/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import {
    ButtonElement,
    DatePickerElement,
    InputElement,
    VALIDATION_CONFIG,
    PrimaryText,
    NIRVANA_COLORS,
    AutoCompleteElement,
    SelectElement,
    ISelectRenderedOption,
} from "nirvana-react-elements"
import moment, { Moment } from "moment-timezone"

import {
    CoveragePortalFlagType,
    POLICIES_CONFIG,
} from "../../../config/policies.config"
import {
    AvailableInsuranceType,
    AvailablePlanType,
    COVERAGE_CONFIG,
} from "../../../config/coverage.config"
import { UtilHelper } from "../../../helpers/util.helper"
import { TOOLTIPS_CONFIG } from "../../../config/tooltips.config"
import { LookupService } from "../../../services/lookup.service"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { BrowserStorageHelper } from "../../../helpers/browserStorageHelper"
import { LookupHelper } from "../../../helpers/lookup.helper"
import { CHECKER_CONFIG } from "../../../config/checker.config"

export const SearchFilterFormComponent: React.FunctionComponent<
    ISearchFilterFormComponentProps
> = props => {
    const {
        handleSubmit,
        formState: { errors },
        control,
        register,
        unregister,
        setValue,
    } = useForm()

    const [defaultStartDate] = useState<Moment>(moment().subtract(30, "days"))
    const [defaultEndDate] = useState<Moment>(moment())
    const [maxStartDate] = useState<Date>(moment().toDate())

    const [selectedFlags, setSelectedFlags] = useState<
        CoveragePortalFlagType[] | undefined
    >(props.existingFilters.flags)

    const [isDataError, setIsDataError] = useState<boolean>(false)

    const getFlagDisplayValue = (flag: CoveragePortalFlagType): string => {
        return POLICIES_CONFIG.flagTypeMappings[flag].label
    }

    const getFlagRenderedOption = (
        flag: CoveragePortalFlagType
    ): ISelectRenderedOption => {
        return {
            displayValue: getFlagDisplayValue(flag),
            value: flag,
        }
    }

    const availableFlagOptions = useMemo<ISelectRenderedOption[]>(() => {
        const flagTypes = props.viewType
            ? POLICIES_CONFIG.flagTypesByViewType[props.viewType]
            : Object.values(CoveragePortalFlagType)

        return flagTypes.map(getFlagRenderedOption)
    }, [props.viewType])

    const availablePlanTypeOptions = useMemo<ISelectRenderedOption[]>(() => {
        return LookupHelper.getRenderedOptionsFromObject<AvailablePlanType>(
            props.viewType
                ? CHECKER_CONFIG.planTypeMappingByViewType[props.viewType]
                : CHECKER_CONFIG.planTypeMapping
        )
    }, [props.viewType])

    const availableInsuranceTypeOptions = useMemo<
        ISelectRenderedOption[]
    >(() => {
        return LookupHelper.getRenderedOptionsFromObject<AvailableInsuranceType>(
            AvailableInsuranceType
        )
    }, [])

    const resetBtn = useMemo<JSX.Element>(() => {
        return (
            <ButtonElement
                label="Reset"
                onClick={props.onResetFilters}
                htmlType="button"
                type="default"
            />
        )
    }, [props.onResetFilters])

    // Set default values (objects) in form
    useEffect(() => {
        props.existingFilters.payer &&
            setValue("payer", props.existingFilters.payer)
    }, [])

    const onFlagSelected = (flag: CoveragePortalFlagType) => {
        setSelectedFlags(current =>
            current?.includes(flag) ? current : [flag, ...(current || [])]
        )
    }

    const onFlagDeselected = (flagDisplayValue: string) => {
        setSelectedFlags(current =>
            (current || []).filter(
                flag => flagDisplayValue !== getFlagDisplayValue(flag)
            )
        )
    }

    const onSubmit = (data: any) => {
        setIsDataError(false)

        let typedData = data as IPoliciesListFiltersData

        // Convert dates to mysql format
        typedData = {
            ...typedData,

            dateFrom: typedData.dateFrom
                ? UtilHelper.dateToMysqlFormat(
                      moment(typedData.dateFrom).toDate()
                  )
                : typedData.dateTo
                ? UtilHelper.dateToMysqlFormat(
                      moment(typedData.dateTo)
                          .subtract(
                              POLICIES_CONFIG.defaultGoBackMonths,
                              "months"
                          )
                          .toDate()
                  )
                : undefined,
            dateTo: typedData.dateTo
                ? UtilHelper.dateToMysqlFormat(
                      moment(typedData.dateTo).toDate()
                  )
                : undefined,
            nextAppointmentDateFrom: typedData.nextAppointmentDateFrom
                ? UtilHelper.dateToMysqlFormat(
                      moment(typedData.nextAppointmentDateFrom).toDate()
                  )
                : undefined,
            nextAppointmentDateTo: typedData.nextAppointmentDateTo
                ? UtilHelper.dateToMysqlFormat(
                      moment(typedData.nextAppointmentDateTo).toDate()
                  )
                : undefined,
            planEndDateFrom: typedData.planEndDateFrom
                ? UtilHelper.dateToMysqlFormat(
                      moment(typedData.planEndDateFrom).toDate()
                  )
                : undefined,
            planEndDateTo: typedData.planEndDateTo
                ? UtilHelper.dateToMysqlFormat(
                      moment(typedData.planEndDateTo).toDate()
                  )
                : undefined,
            memberDob: typedData.memberDob
                ? UtilHelper.dateToMysqlFormat(
                      moment(typedData.memberDob).toDate()
                  )
                : undefined,

            flags: selectedFlags?.length ? selectedFlags : undefined,
        }

        if (props.isRequired) {
            const providedMemberData = Object.keys(typedData)
                .filter(key =>
                    POLICIES_CONFIG.searchMemberDataFilters.includes(
                        key as keyof IPoliciesListFiltersData
                    )
                )
                .map(key => typedData[key])
                .filter(item => !!item)

            const providedGeneralData = Object.keys(typedData)
                .map(key => typedData[key])
                .filter(item => !!item)

            if (
                (providedMemberData.length &&
                    providedMemberData.length <
                        POLICIES_CONFIG.minMemberDataFields) ||
                providedGeneralData.length <
                    POLICIES_CONFIG.minOverallDataFields
            ) {
                setIsDataError(true)

                return
            }
        }

        props.persistenceBrowserStorageKey &&
            BrowserStorageHelper.set(
                props.persistenceBrowserStorageKey,
                typedData
            )

        props.onFiltersSubmitted(typedData)
    }

    return (
        <div className={`relative ${props.className}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div
                    className="
                        flex
                        md:block
                    "
                >
                    <DatePickerElement
                        className="
                            mr-16px flex-1
                            md:mr-0px
                        "
                        name="dateFrom"
                        label={POLICIES_CONFIG.filtersDisplayMapping.dateFrom}
                        defaultValue={
                            props.existingFilters.dateFrom
                                ? moment(props.existingFilters.dateFrom)
                                : props.withDefaultDateRange
                                ? defaultStartDate
                                : undefined
                        }
                        reactHookFormValidations={
                            props.isRequired
                                ? {
                                      required: VALIDATION_CONFIG.required,
                                  }
                                : undefined
                        }
                        shouldValidate
                        reactHookFormErrors={errors}
                        reactHookFormRegister={register}
                        reactHookFormUnregister={unregister}
                        reactHookFormSet={setValue}
                        maxDate={maxStartDate}
                        allowClear={!props.isRequired}
                        isLabelStatic
                    />

                    <DatePickerElement
                        className="
                            mr-16px flex-1
                            md:mr-0px md:mt-44px
                        "
                        name="dateTo"
                        label={POLICIES_CONFIG.filtersDisplayMapping.dateTo}
                        defaultValue={
                            props.existingFilters.dateTo
                                ? moment(props.existingFilters.dateTo)
                                : props.withDefaultDateRange
                                ? defaultEndDate
                                : undefined
                        }
                        reactHookFormValidations={
                            props.isRequired
                                ? {
                                      required: VALIDATION_CONFIG.required,
                                  }
                                : undefined
                        }
                        shouldValidate
                        reactHookFormErrors={errors}
                        reactHookFormRegister={register}
                        reactHookFormUnregister={unregister}
                        reactHookFormSet={setValue}
                        maxDate={maxStartDate}
                        allowClear={!props.isRequired}
                        isLabelStatic
                    />

                    {!props.withResetBenefitsStatus ? (
                        <div className="flex-1" />
                    ) : (
                        <SelectElement
                            className="flex-1 md:mt-44px"
                            label={
                                POLICIES_CONFIG.filtersDisplayMapping
                                    .resetBenefitsStatus
                            }
                            name="resetBenefitsStatus"
                            allowClear={true}
                            reactHookFormRegister={register}
                            reactHookFormSet={setValue}
                            reactHookFormUnregister={unregister}
                            renderedOptions={Object.values(
                                COVERAGE_CONFIG.selectRenderedResetBenefitStatus
                            )}
                            defaultValue={
                                props.existingFilters.resetBenefitsStatus
                                    ? COVERAGE_CONFIG
                                          .selectRenderedResetBenefitStatus[
                                          props.existingFilters
                                              .resetBenefitsStatus
                                      ].displayValue
                                    : undefined
                            }
                        />
                    )}
                </div>

                <div className="flex md:block mt-44px">
                    <DatePickerElement
                        className="
                            mr-16px flex-1
                            md:mr-0px
                        "
                        name="nextAppointmentDateFrom"
                        label={
                            POLICIES_CONFIG.filtersDisplayMapping
                                .nextAppointmentDateFrom
                        }
                        defaultValue={
                            props.existingFilters.nextAppointmentDateFrom
                                ? moment(
                                      props.existingFilters
                                          .nextAppointmentDateFrom
                                  )
                                : undefined
                        }
                        shouldValidate
                        reactHookFormErrors={errors}
                        reactHookFormRegister={register}
                        reactHookFormUnregister={unregister}
                        reactHookFormSet={setValue}
                        minDate={maxStartDate}
                        allowClear
                        isLabelStatic
                    />

                    <DatePickerElement
                        className="
                            mr-16px flex-1
                            md:mr-0px md:mt-44px
                        "
                        name="nextAppointmentDateTo"
                        label={
                            POLICIES_CONFIG.filtersDisplayMapping
                                .nextAppointmentDateTo
                        }
                        defaultValue={
                            props.existingFilters.nextAppointmentDateTo
                                ? moment(
                                      props.existingFilters
                                          .nextAppointmentDateTo
                                  )
                                : undefined
                        }
                        shouldValidate
                        reactHookFormErrors={errors}
                        reactHookFormRegister={register}
                        reactHookFormUnregister={unregister}
                        reactHookFormSet={setValue}
                        minDate={maxStartDate}
                        allowClear
                        isLabelStatic
                    />

                    <div className="flex-1" />
                </div>

                {!props.ignorePlanEndDateFilters && (
                    <div className="flex md:block mt-44px">
                        <DatePickerElement
                            className="
                                mr-16px flex-1
                                md:mr-0px
                            "
                            name="planEndDateFrom"
                            label={
                                POLICIES_CONFIG.filtersDisplayMapping
                                    .planEndDateFrom
                            }
                            defaultValue={
                                props.existingFilters.planEndDateFrom
                                    ? moment(
                                          props.existingFilters.planEndDateFrom
                                      )
                                    : undefined
                            }
                            shouldValidate
                            reactHookFormErrors={errors}
                            reactHookFormRegister={register}
                            reactHookFormUnregister={unregister}
                            reactHookFormSet={setValue}
                            allowClear
                            isLabelStatic
                        />

                        <DatePickerElement
                            className="
                                mr-16px flex-1
                                md:mr-0px md:mt-44px
                            "
                            name="planEndDateTo"
                            label={
                                POLICIES_CONFIG.filtersDisplayMapping
                                    .planEndDateTo
                            }
                            defaultValue={
                                props.existingFilters.planEndDateTo
                                    ? moment(
                                          props.existingFilters.planEndDateTo
                                      )
                                    : undefined
                            }
                            shouldValidate
                            reactHookFormErrors={errors}
                            reactHookFormRegister={register}
                            reactHookFormUnregister={unregister}
                            reactHookFormSet={setValue}
                            allowClear
                            isLabelStatic
                        />

                        <div className="flex-1" />
                    </div>
                )}

                {props.withFlags && (
                    <div className="flex mt-44px mb-32px">
                        <SelectElement
                            className="flex-1"
                            selectClassName={
                                selectedFlags && selectedFlags.length > 4
                                    ? "h-88px!"
                                    : undefined
                            }
                            name="flags"
                            mode="multiple"
                            label={POLICIES_CONFIG.filtersDisplayMapping.flags}
                            renderedOptions={availableFlagOptions}
                            onSelected={onFlagSelected}
                            onDeselected={onFlagDeselected}
                            itemRenderer={getFlagRenderedOption}
                            // @ts-ignore
                            defaultValue={selectedFlags}
                        />
                    </div>
                )}

                <div className="mt-16px p-16px rounded-12px bg-brand-warmLight">
                    {props.isRequired && (
                        <PrimaryText
                            typography="h6"
                            color={
                                isDataError
                                    ? NIRVANA_COLORS.brand.error
                                    : undefined
                            }
                        >
                            Enter at least {POLICIES_CONFIG.minMemberDataFields}{" "}
                            fields to make a search from these inputs
                        </PrimaryText>
                    )}

                    <div className="flex md:block mt-44px">
                        <InputElement
                            className="
                                mr-16px flex-1
                                md:mr-0px
                            "
                            label={
                                POLICIES_CONFIG.filtersDisplayMapping
                                    .memberFirstName
                            }
                            name="memberFirstName"
                            reactHookControl={control}
                            reactHookValidations={{
                                minLength: VALIDATION_CONFIG.minLength,
                                maxLength: VALIDATION_CONFIG.maxLength,
                            }}
                            reactHookErrors={errors}
                            defaultValue={props.existingFilters.memberFirstName}
                            isLabelStatic
                        />

                        <InputElement
                            className="
                                flex-1
                                md:mt-44px
                            "
                            label={
                                POLICIES_CONFIG.filtersDisplayMapping
                                    .memberLastName
                            }
                            name="memberLastName"
                            reactHookControl={control}
                            reactHookValidations={{
                                minLength: VALIDATION_CONFIG.minLength,
                                maxLength: VALIDATION_CONFIG.maxLength,
                            }}
                            reactHookErrors={errors}
                            defaultValue={props.existingFilters.memberLastName}
                            isLabelStatic
                        />
                    </div>
                    <div className="flex md:block mt-44px">
                        <InputElement
                            className="
                                mr-16px flex-1
                                md:mr-0px md:mt-44px
                            "
                            label={
                                POLICIES_CONFIG.filtersDisplayMapping.memberId
                            }
                            name="memberId"
                            reactHookControl={control}
                            reactHookValidations={{
                                minLength: VALIDATION_CONFIG.minLength,
                                maxLength: VALIDATION_CONFIG.maxLength,
                            }}
                            reactHookErrors={errors}
                            defaultValue={props.existingFilters.memberId}
                            isLabelStatic
                        />

                        <DatePickerElement
                            className="
                                flex-1
                                md:mt-44px
                            "
                            name="memberDob"
                            label={
                                POLICIES_CONFIG.filtersDisplayMapping.memberDob
                            }
                            defaultValue={
                                props.existingFilters.memberDob
                                    ? moment(props.existingFilters.memberDob)
                                    : undefined
                            }
                            shouldValidate
                            reactHookFormErrors={errors}
                            reactHookFormRegister={register}
                            reactHookFormUnregister={unregister}
                            reactHookFormSet={setValue}
                            maxDate={maxStartDate}
                            allowClear
                            isLabelStatic
                        />
                    </div>
                </div>

                <div className="mt-32px">
                    {props.isRequired && (
                        <PrimaryText
                            typography="h6"
                            color={
                                isDataError
                                    ? NIRVANA_COLORS.brand.error
                                    : undefined
                            }
                        >
                            Or enter any of the following inputs
                        </PrimaryText>
                    )}

                    <div className="flex md:block mt-44px">
                        <InputElement
                            className="
                                mr-16px flex-1
                                md:mr-0px
                            "
                            label={
                                POLICIES_CONFIG.filtersDisplayMapping.patientId
                            }
                            name="patientId"
                            reactHookControl={control}
                            reactHookValidations={{
                                maxLength: VALIDATION_CONFIG.maxLength,
                            }}
                            reactHookErrors={errors}
                            defaultValue={props.existingFilters.patientId}
                            isLabelStatic
                        />

                        <SelectElement
                            className="
                                mr-16px flex-1
                                md:mr-0px md:mt-44px
                            "
                            label={
                                POLICIES_CONFIG.filtersDisplayMapping
                                    .patientType
                            }
                            name="patientType"
                            allowClear={true}
                            reactHookFormRegister={register}
                            reactHookFormSet={setValue}
                            reactHookFormUnregister={unregister}
                            renderedOptions={Object.values(
                                COVERAGE_CONFIG.selectRenderedPatientTypes
                            )}
                            defaultValue={
                                props.existingFilters.patientType
                                    ? COVERAGE_CONFIG
                                          .selectRenderedPatientTypes[
                                          props.existingFilters.patientType
                                      ].displayValue
                                    : undefined
                            }
                        />

                        <InputElement
                            className="
                                flex-1
                                md:mr-0px md:mt-44px
                            "
                            label={
                                POLICIES_CONFIG.filtersDisplayMapping.groupId
                            }
                            name="groupId"
                            reactHookControl={control}
                            reactHookValidations={{
                                minLength: VALIDATION_CONFIG.minLength,
                                maxLength: VALIDATION_CONFIG.maxLength,
                            }}
                            reactHookErrors={errors}
                            defaultValue={props.existingFilters.groupId}
                            isLabelStatic
                        />
                    </div>

                    <div className="flex md:block mt-44px">
                        <InputElement
                            className="
                                mr-16px flex-1
                                md:mr-0px
                            "
                            label={
                                POLICIES_CONFIG.filtersDisplayMapping.groupName
                            }
                            name="groupName"
                            reactHookControl={control}
                            reactHookValidations={{
                                minLength: VALIDATION_CONFIG.minLength,
                                maxLength: VALIDATION_CONFIG.maxLength,
                            }}
                            reactHookErrors={errors}
                            defaultValue={props.existingFilters.groupName}
                            isLabelStatic
                        />

                        <SelectElement
                            className="
                                mr-16px flex-1
                                md:mr-0px md:mt-44px
                            "
                            label={
                                POLICIES_CONFIG.filtersDisplayMapping.planType
                            }
                            name="planType"
                            allowClear={true}
                            reactHookFormRegister={register}
                            reactHookFormSet={setValue}
                            reactHookFormUnregister={unregister}
                            renderedOptions={availablePlanTypeOptions}
                            defaultValue={
                                props.existingFilters.planType
                                    ? CHECKER_CONFIG.planTypeMapping[
                                          props.existingFilters.planType
                                      ]
                                    : undefined
                            }
                        />

                        <InputElement
                            className="
                                flex-1
                                md:mr-0px md:mt-44px
                            "
                            label={
                                POLICIES_CONFIG.filtersDisplayMapping.planName
                            }
                            name="planName"
                            reactHookControl={control}
                            reactHookValidations={{
                                minLength: VALIDATION_CONFIG.minLength,
                                maxLength: VALIDATION_CONFIG.maxLength,
                            }}
                            reactHookErrors={errors}
                            defaultValue={props.existingFilters.planName}
                            isLabelStatic
                        />
                    </div>

                    <div className="flex md:block mt-44px">
                        <div
                            className="
                                mr-16px flex-1
                                md:mr-0px
                            "
                        >
                            <AutoCompleteElement
                                name="payer"
                                label={
                                    POLICIES_CONFIG.filtersDisplayMapping.payer
                                }
                                tooltip={TOOLTIPS_CONFIG.calculate.payer}
                                dataFetcher={(search?: string) =>
                                    LookupService.lookupPayers(search)
                                }
                                itemRenderer={LookupHelper.getRenderedPayer}
                                reactHookFormErrors={errors}
                                reactHookFormRegister={register}
                                reactHookFormUnregister={unregister}
                                reactHookFormSet={setValue}
                                debounceMilliseconds={250}
                                defaultValue={
                                    props.existingFilters.payer
                                        ? LookupHelper.getRenderedPayer(
                                              props.existingFilters.payer
                                          ).displayValue
                                        : undefined
                                }
                                shouldValidate
                                isLabelStatic
                                ignoreAutoSelectionOnBlur
                                clearOnEmptySearch
                                notFoundTitle="Don’t see your payer?"
                                notFoundSubtitle={`
                                    <a
                                        href="mailto:${GENERAL_CONFIG.supportEmail}?subject=Insurance provider not found"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Contact Support
                                    </a>
                                `}
                                notFoundAction={() => (
                                    <a
                                        href={
                                            GENERAL_CONFIG.supportedInsurersUrl
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <ButtonElement
                                            label="View Supported Payers"
                                            type="primary"
                                            htmlType="button"
                                            size="large"
                                        />
                                    </a>
                                )}
                            />

                            <a
                                href={GENERAL_CONFIG.supportedInsurersUrl}
                                target="_blank"
                                rel="noreferrer"
                                className="no-underline!"
                            >
                                <PrimaryText
                                    className="mt-8px"
                                    typography="caption"
                                >
                                    View all supported payers
                                </PrimaryText>
                            </a>
                        </div>
                        <SelectElement
                            className="
                                mr-16px flex-1
                                md:mr-0px md:mt-44px
                            "
                            label={
                                POLICIES_CONFIG.filtersDisplayMapping
                                    .insuranceType
                            }
                            name="insuranceType"
                            allowClear={true}
                            reactHookFormRegister={register}
                            reactHookFormSet={setValue}
                            reactHookFormUnregister={unregister}
                            renderedOptions={availableInsuranceTypeOptions}
                            defaultValue={props.existingFilters.insuranceType}
                        />

                        {/* div below is a placeholder */}
                        <div className="flex-1" />
                    </div>
                </div>

                <div className="mt-32px flex items-center">
                    {props.onCancelFilters && resetBtn}

                    <div className="flex-1" />

                    {!props.onCancelFilters && resetBtn}

                    {props.onCancelFilters && (
                        <ButtonElement
                            label="Cancel"
                            onClick={props.onCancelFilters}
                            htmlType="button"
                            type="default"
                        />
                    )}

                    <ButtonElement
                        className="ml-16px"
                        label={props.submitBtnLabel || "Search"}
                        htmlType="submit"
                        type="primary"
                    />
                </div>
            </form>
        </div>
    )
}
