import {
    BrowserStorageHelper as NirvanaBrowserStorageHelper,
    BrowserStorageType,
    PolicyModality,
} from "nirvana-react-elements"

import { GENERAL_CONFIG } from "../config/general.config"
import { PoliciesHelper } from "./policies.helper"
import { UtilHelper } from "./util.helper"

export class BrowserStorageHelper extends NirvanaBrowserStorageHelper {
    // REVIEW / HISTORY

    /**
     * Get default configuration for columns for historical view
     * Uses LOCAL storage
     */
    static getDefaultHistoricalPoliciesColumnsConfiguration(
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ): IPolicyColumnConfiguration[] {
        return BrowserStorageHelper.getDefaultPoliciesColumnsConfiguration(
            BrowserStorageHelper.getModalitiesBasedStorageKey(
                GENERAL_CONFIG.browserStorageKeys
                    .historicalPoliciesColumnsConfiguration,
                availableModalities
            ),
            PoliciesHelper.getDefaultHistoricalChecksColumns(
                availableModalities
            )
        )
    }

    // REPORTS

    /**
     * Get default configuration for columns for reports view
     * Uses LOCAL storage
     */
    static getDefaultReportsPoliciesColumnsConfiguration(
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ): IPolicyColumnConfiguration[] {
        return BrowserStorageHelper.getDefaultPoliciesColumnsConfiguration(
            BrowserStorageHelper.getModalitiesBasedStorageKey(
                GENERAL_CONFIG.browserStorageKeys
                    .reportsPoliciesColumnsConfiguration,
                availableModalities
            ),
            PoliciesHelper.getDefaultReportsColumns(availableModalities)
        )
    }

    /**
     * Get default configuration for filters for reports view
     * Uses LOCAL storage
     */
    static getDefaultReportsPoliciesFiltersConfiguration(
        defaultDateFrom?: Date
    ): IPoliciesListFiltersData {
        return (
            BrowserStorageHelper.get(
                GENERAL_CONFIG.browserStorageKeys
                    .reportsPoliciesFiltersConfiguration
            ) || {
                ...(defaultDateFrom
                    ? {
                          dateFrom:
                              UtilHelper.dateToMysqlFormat(defaultDateFrom),
                      }
                    : {}),
            }
        )
    }

    // MEDICAID

    /**
     * Get default configuration for columns for reports view
     * Uses LOCAL storage
     */
    static getDefaultMedicaidReportsPoliciesColumnsConfiguration(
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ): IPolicyColumnConfiguration[] {
        return BrowserStorageHelper.getDefaultPoliciesColumnsConfiguration(
            BrowserStorageHelper.getModalitiesBasedStorageKey(
                GENERAL_CONFIG.browserStorageKeys
                    .medicaidPoliciesColumnsConfiguration,
                availableModalities
            ),
            PoliciesHelper.getDefaultMedicaidReportsColumns(availableModalities)
        )
    }

    /**
     * Get default configuration for filters for medicaid view
     * Uses LOCAL storage
     */
    static getDefaultMedicaidPoliciesFiltersConfiguration(
        defaultDateFrom?: Date
    ): IPoliciesListFiltersData {
        return (
            BrowserStorageHelper.get(
                GENERAL_CONFIG.browserStorageKeys
                    .medicaidPoliciesFiltersConfiguration
            ) || {
                ...(defaultDateFrom
                    ? {
                          dateFrom:
                              UtilHelper.dateToMysqlFormat(defaultDateFrom),
                      }
                    : {}),
            }
        )
    }

    // OVERRIDES

    /**
     * Get default configuration for columns for overrides view
     * Uses LOCAL storage
     */
    static getDefaultOverridesPoliciesColumnsConfiguration(
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ): IPolicyColumnConfiguration[] {
        return BrowserStorageHelper.getDefaultPoliciesColumnsConfiguration(
            BrowserStorageHelper.getModalitiesBasedStorageKey(
                GENERAL_CONFIG.browserStorageKeys
                    .overridesPoliciesColumnsConfiguration,
                availableModalities
            ),
            PoliciesHelper.getDefaultOverridesColumns(availableModalities)
        )
    }

    /**
     * Get default configuration for filters for overrides view
     * Uses LOCAL storage
     */
    static getDefaultOverridesPoliciesFiltersConfiguration(
        defaultDateFrom?: Date
    ): IPoliciesListFiltersData {
        return (
            BrowserStorageHelper.get(
                GENERAL_CONFIG.browserStorageKeys
                    .overridesPoliciesFiltersConfiguration
            ) || {
                ...(defaultDateFrom
                    ? {
                          dateFrom:
                              UtilHelper.dateToMysqlFormat(defaultDateFrom),
                      }
                    : {}),
            }
        )
    }

    // PLAN YEAR RESETS

    /**
     * Get default configuration for columns for plan year resets view
     * Uses LOCAL storage
     */
    static getDefaultPlanYearResetsPoliciesColumnsConfiguration(
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ): IPolicyColumnConfiguration[] {
        return BrowserStorageHelper.getDefaultPoliciesColumnsConfiguration(
            BrowserStorageHelper.getModalitiesBasedStorageKey(
                GENERAL_CONFIG.browserStorageKeys
                    .planYearResetsPoliciesColumnsConfiguration,
                availableModalities
            ),
            PoliciesHelper.getDefaultPlanYearResetsColumns(availableModalities)
        )
    }

    /**
     * Get default configuration for filters for plan year resets view
     * Uses LOCAL storage
     */
    static getDefaultPlanYearResetsPoliciesFiltersConfiguration(
        defaultDateFrom?: Date
    ): IPoliciesListFiltersData {
        return (
            BrowserStorageHelper.get(
                GENERAL_CONFIG.browserStorageKeys
                    .planYearResetsPoliciesFiltersConfiguration
            ) || {
                ...(defaultDateFrom
                    ? {
                          dateFrom:
                              UtilHelper.dateToMysqlFormat(defaultDateFrom),
                      }
                    : {}),
            }
        )
    }

    // COMMON

    /**
     * Save columns configurations for future usage
     */
    static saveColumnsConfigurations(
        key: string,
        columnsConfigurations: IPolicyColumnConfiguration[],
        availableModalities = [PolicyModality.MENTAL_HEALTH]
    ) {
        BrowserStorageHelper.set(
            BrowserStorageHelper.getModalitiesBasedStorageKey(
                key,
                availableModalities
            ),
            columnsConfigurations,
            BrowserStorageType.localStorage
        )
    }

    /**
     * Make sure to return function for getting value inside each object
     */
    static extendColumnsConfigurationsWithFunctionValues(
        columns: IPolicyColumnConfiguration[],
        defaultValues: IPolicyColumnConfiguration[]
    ): IPolicyColumnConfiguration[] {
        return columns.map(item => {
            const neededInitialItem = defaultValues.find(
                initial => initial.label === item.label
            )

            if (!neededInitialItem) {
                return item
            }

            return {
                ...item,

                getCustomValue: neededInitialItem.getCustomValue,
            }
        })
    }

    /**
     * Get storage key that is modalities based
     */
    private static getModalitiesBasedStorageKey(
        key: string,
        availableModalities: PolicyModality[]
    ): string {
        return `${key}_${availableModalities.join("_")}`
    }

    /**
     * Get default values for policies columns configurations
     */
    private static getDefaultPoliciesColumnsConfiguration(
        browserStorageKey: string,
        defaultValues: IPolicyColumnConfiguration[]
    ): IPolicyColumnConfiguration[] {
        let result: IPolicyColumnConfiguration[] =
            BrowserStorageHelper.get(
                browserStorageKey,
                undefined,
                BrowserStorageType.localStorage
            ) || defaultValues

        // Add columns that exist in defaults but don't in existing
        // Basically if added new columns it will be added to the end automatically
        const missingColumns = defaultValues.filter(
            item => !result.find(innerItem => innerItem.label === item.label)
        )

        result = [...result, ...missingColumns]

        return BrowserStorageHelper.extendColumnsConfigurationsWithFunctionValues(
            result,
            defaultValues
        )
    }
}
