/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { AvatarElement } from "nirvana-react-elements"

import {
    ROUTES_CONFIG,
    ROUTES_MENU_FREE_CONFIG,
} from "../../config/routes.config"
import { AuthHelper } from "../../helpers/auth.helper"
import { MENU_CONFIG } from "../../config/menu.config"
import { GENERAL_CONFIG } from "../../config/general.config"
import { SelectedPracticeComponent } from "./selectedPractice.component"
import { PermissionsHelper } from "../../helpers/permissions.helper"
import { useAppSelector } from "../../store/selectors/app.selector"
import { profileSelector } from "../../store/selectors/profile.selector"
import { selectedPracticeRoleSelector } from "../../store/selectors/selectedPracticeRole.selector"
import { runtimeSelector } from "../../store/selectors/runtime.selector"
import { FeatureFlagsHelper } from "../../helpers/featureFlags.helper"
import { DesktopMenuComponent } from "./menu/desktopMenu.component"
import { MobileMenuComponent } from "./menu/mobileMenu.component"
import { useHasPermissions } from "../../hooks/hasPermissions.hook"
import { AvailableCoveragePortalPermission } from "../../config/rolesPermissions.config"
import { EagleEyeComponent } from "./eagleEye.component"

import logo from "../../assets/images/logo.svg"

export const HeaderComponent: React.FunctionComponent = () => {
    const location = useLocation()

    const runtime = useAppSelector(runtimeSelector)
    const profile = useAppSelector(profileSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const [headerTopPosition, setHeaderTopPosition] = useState<number>(0)

    const hasEagleEyePermission = useHasPermissions(
        AvailableCoveragePortalPermission.useEagleEye,
        undefined,
        false
    )

    const [eagleEyeData] = useState<IEagleEyeViewAsConfig | null>(
        AuthHelper.getEagleEyeViewAsData()
    )

    const isAllowedMenuItem = (item: IMenuItem): boolean => {
        return (
            (!item.featureFlag ||
                FeatureFlagsHelper.isFeatureFlagEnabled(
                    runtime.enabledFeatureFlags || [],
                    item.featureFlag
                )) &&
            (!item.neededPermissions ||
                PermissionsHelper.hasPermission(
                    item.neededPermissions,
                    selectedPracticeRole,
                    item.neededPermissionsLogicalOperator
                ))
        )
    }

    const allowedMenuItems = useMemo<IMenuItem[]>(() => {
        if (!runtime.enabledFeatureFlags) {
            return []
        }

        return (JSON.parse(JSON.stringify(MENU_CONFIG.mainMenu)) as IMenuItem[])
            .filter(item => {
                if (item.submenu?.length) {
                    item.submenu = item.submenu.filter(subItem => {
                        return isAllowedMenuItem(subItem)
                    })
                }

                const isMultipleSubmenu =
                    item.submenu?.length && item.submenu.length > 1

                return isMultipleSubmenu ? true : isAllowedMenuItem(item)
            })
            .map(item => {
                return item.submenu?.length === 1
                    ? {
                          ...item.submenu[0],

                          label: item.label,
                      }
                    : item
            })
    }, [selectedPracticeRole?.id, runtime.enabledFeatureFlags])

    // When scrolling the page vertically -> make header hide itself by adjusting it's top position
    useEffect(() => {
        const onWindowScroll = (event: Event) => {
            setHeaderTopPosition(-1 * window.scrollY)
        }

        window.addEventListener("scroll", onWindowScroll)

        return () => {
            window.removeEventListener("scroll", onWindowScroll)
        }
    }, [])

    return (
        <div
            style={{
                height: `${GENERAL_CONFIG.headerHeight}px`,
            }}
        >
            <div
                className="
                    fixed left-0 w-screen z-30
                    flex items-center py-16px px-40px
                    sm:block sm:px-16px md:pr-0px
                "
                style={{
                    top: `${headerTopPosition}px`,
                }}
            >
                <div className="mr-56px">
                    {AuthHelper.isLoggedIn() ? (
                        <Link to={ROUTES_CONFIG.baseApplicationUrl}>
                            <img src={logo} alt="Nirvana" title="Nirvana" />
                        </Link>
                    ) : (
                        <a href={GENERAL_CONFIG.mainWebsiteUrl}>
                            <img src={logo} alt="Nirvana" title="Nirvana" />
                        </a>
                    )}
                </div>

                {AuthHelper.isLoggedIn() &&
                !~ROUTES_MENU_FREE_CONFIG.indexOf(location.pathname) &&
                profile ? (
                    <div className="flex items-center flex-1 sm:mt-24px">
                        <DesktopMenuComponent
                            menuItems={allowedMenuItems}
                            className="flex-1 mr-24px"
                        />

                        <div className="flex-1 hidden md:block sm:hidden" />

                        {hasEagleEyePermission || eagleEyeData ? (
                            <EagleEyeComponent className="mr-16px" />
                        ) : null}

                        {selectedPracticeRole ? (
                            <div
                                className="
                                    relative flex items-center rounded-full
                                    border border-solid border-brand-primary
                                "
                            >
                                <SelectedPracticeComponent
                                    className="
                                        flex-1 border-r border-solid border-brand-primary
                                        pr-8px py-4px pl-16px
                                    "
                                />

                                <Link
                                    to={ROUTES_CONFIG.profileUrl}
                                    className="px-8px py-4px"
                                >
                                    <AvatarElement
                                        user={profile}
                                        width={32}
                                        fontClassName="pt-3px fs-16px lh-16px"
                                    />
                                </Link>
                            </div>
                        ) : null}

                        <div className="flex-1 hidden sm:block" />

                        <MobileMenuComponent
                            menuItems={allowedMenuItems}
                            className="ml-16px"
                        />
                    </div>
                ) : null}
            </div>
        </div>
    )
}
