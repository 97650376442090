import { createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit"

import {
    checkerReceivedContinuousMonitoringCoverageResult,
    checkerReceivedIntakeCoverageResult,
    checkerReceivedMedicaidCoverageResult,
    checkerSetContinuousMonitoringInputData,
    checkerSetCoverageChecksSubmitted,
    checkerSetIntakeInputData,
    checkerSetMedicaidInputData,
    checkerSetRunningState,
} from "../slices/checker.slice"
import {
    runtimeStartLoading,
    runtimeStopLoading,
} from "../slices/runtime.slice"
import {
    CHECKER_CONFIG,
    CoverageCheckerManualCheckType,
    CoverageCheckerRunningState,
} from "../../config/checker.config"
import { CheckerService } from "../../services/checker.service"
import { RootState } from "../store"
import { UtilHelper } from "../../helpers/util.helper"
import { ToastrHelper } from "../../helpers/toastr.helper"
import { PayerCoverageCheckNetwork } from "../../config/coverage.config"

/**
 * Run bulk coverage checks for checker in continuous monitoring mode
 */
export const checkerRunContinuousMonitoringChecks = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<IContinuousMonitoringCoverageCheckInputData[]>
>(
    "checkerRunContinuousMonitoringChecksPrefix",
    async (action, { dispatch, getState }) => {
        await runBulkCoverageChecks<IContinuousMonitoringCoverageCheckInputData>(
            action.payload,
            action.practice,
            getState() as RootState,
            inputData => {
                dispatch(checkerSetContinuousMonitoringInputData(inputData))
            },
            runContinuousMonitoringCoverageCheck,
            dispatch,
            "checkerRunContinuousMonitoringChecksLoading",
            action.onSuccess,
            action.onError,
            action.onFinally
        )
    }
)

/**
 * Run bulk coverage checks for checker in medicaid mode
 */
export const checkerRunMedicaidChecks = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<IMedicaidCheckerInputData[]>
>("checkerRunMedicaidChecksPrefix", async (action, { dispatch, getState }) => {
    await runBulkCoverageChecks<IMedicaidCheckerInputData>(
        action.payload,
        action.practice,
        getState() as RootState,
        inputData => {
            dispatch(checkerSetMedicaidInputData(inputData))
        },
        runMedicaidCoverageCheck,
        dispatch,
        "checkerRunMedicaidChecksLoading",
        action.onSuccess,
        action.onError,
        action.onFinally
    )
})

/**
 * Run smart check for checker
 */
export const checkerRunSmartCheck = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        requestData: IGetCoverageEstimateData
        inNetwork: boolean
    }>
>("checkerRunSmartCheckPrefix", async (action, { dispatch, getState }) => {
    dispatch(runtimeStartLoading("checkerRunSmartCheckLoading"))

    try {
        const result = await CheckerService.runSmartScanCoverageCheck(
            action.payload.requestData,
            action.practice,
            action.payload.inNetwork
        )

        if (result) {
            const rootState = getState() as RootState

            const checkResult: ICoverageResult = {
                ...result,

                resultId: action.payload.requestData.id,
                isSmartScanResult: true,
            }

            switch (rootState.checker.manualCheckType) {
                case CoverageCheckerManualCheckType.CONTINUOUS_MONITORING:
                    dispatch(
                        checkerReceivedContinuousMonitoringCoverageResult(
                            checkResult
                        )
                    )

                    break

                case CoverageCheckerManualCheckType.INTAKE:
                    dispatch(
                        checkerReceivedIntakeCoverageResult({
                            ...rootState.checker.intakeCheck.result,

                            [checkResult.isInNetworkCheck
                                ? PayerCoverageCheckNetwork.IN
                                : PayerCoverageCheckNetwork.OUT]: checkResult,
                        })
                    )

                    break
            }

            action.onSuccess && action.onSuccess(result)
        } else {
            ToastrHelper.warning(
                "Unfortunately, Nirvana was not able to recover selected policy"
            )

            action.onError && action.onError(result)
        }
    } catch (e) {
        action.onError && action.onError()
    } finally {
        dispatch(runtimeStopLoading("checkerRunSmartCheckLoading"))

        action.onFinally && action.onFinally()
    }
})

/**
 * Run intake check for checker
 */
export const checkerRunIntakeCheck = createAsyncThunk<
    void,
    IThunkActionWithPracticeData<{
        requestData: IIntakeCheckerInputData
    }>
>("checkerRunIntakeCheckPrefix", async (action, { dispatch }) => {
    dispatch(runtimeStartLoading("checkerRunIntakeCheckLoading"))

    try {
        dispatch(checkerSetCoverageChecksSubmitted(true))
        dispatch(checkerSetIntakeInputData(action.payload.requestData))

        const result = await CheckerService.runIntakeCoverageCheck(
            action.payload.requestData,
            action.practice
        )

        // Sleep for X seconds here so breathing spinner stays longer during this process
        await UtilHelper.sleep(3)

        if (
            result &&
            (result[PayerCoverageCheckNetwork.IN] ||
                result[PayerCoverageCheckNetwork.OUT])
        ) {
            dispatch(checkerReceivedIntakeCoverageResult(result))

            dispatch(
                checkerSetRunningState(
                    CoverageCheckerRunningState.RESULTS_MANUAL
                )
            )

            action.onSuccess && action.onSuccess(result)
        } else {
            dispatch(checkerSetCoverageChecksSubmitted(false))

            ToastrHelper.warning(
                "Unfortunately, Nirvana was not able to identify coverage for this patient"
            )

            action.onError && action.onError(result)
        }
    } catch (e) {
        action.onError && action.onError()
    } finally {
        dispatch(runtimeStopLoading("checkerRunIntakeCheckLoading"))

        action.onFinally && action.onFinally()
    }
})

/**
 * Common method for running checks in bulk
 */
const runBulkCoverageChecks = async <InputDataType extends { id?: string }>(
    data: InputDataType[],
    practice: IPractice,
    globalState: RootState,
    onSetInputDataInState: (data: InputDataType[]) => void,
    runSingleCheck: (
        data: InputDataType,
        practice: IPractice,
        dispatch: ThunkDispatch<unknown, unknown, any>,
        triesCount?: number
    ) => Promise<void>,
    dispatch: ThunkDispatch<unknown, unknown, any>,
    runtimeLoaderIdentifier: string,
    onSuccess?: (data?: any) => void,
    onError?: (data?: any) => void,
    onFinally?: () => void
) => {
    dispatch(runtimeStartLoading(runtimeLoaderIdentifier))

    // IF it's internal user - we'll have extended batch size
    const batchSize = UtilHelper.isInternalUser(globalState.userDetails.profile)
        ? CHECKER_CONFIG.processBatchSizeExtended
        : CHECKER_CONFIG.processBatchSize

    try {
        // Sleep for X seconds here so breathing spinner stays longer during this process
        await UtilHelper.sleep(3)

        // Filter input data, it can have empty values
        // Need to copy these because by default they are immutable
        let inputData = JSON.parse(
            JSON.stringify(data.filter(item => !!item))
        ) as InputDataType[]

        if (!inputData.length) {
            return
        }

        // Generate unique id for each of the input data array item
        // This id will be used for listening for results and mapping to needed input data
        try {
            inputData = inputData.map(item => {
                item.id = item.id || UtilHelper.generateUniqueVarchar(100)

                return item
            })
        } catch (e) {
            console.log(e)
        }

        onSetInputDataInState(inputData)
        dispatch(checkerSetCoverageChecksSubmitted(true))

        let processedCount = 0

        while (processedCount < inputData.length) {
            const coverageChecks = [] as Promise<any>[]

            // Generate processing promises with our "pagination' based on batch size
            for (let i = processedCount; i < processedCount + batchSize; i++) {
                if (!inputData[i]) {
                    break
                }

                coverageChecks.push(
                    runSingleCheck(inputData[i], practice, dispatch)
                )
            }

            processedCount += batchSize

            await Promise.all(coverageChecks)
        }

        onSuccess?.()
    } catch (e) {
        dispatch(checkerSetCoverageChecksSubmitted(false))

        onError?.()
    } finally {
        dispatch(runtimeStopLoading(runtimeLoaderIdentifier))

        onFinally?.()
    }
}

/**
 * Run single coverage check for continuous monitoring checker
 */
const runContinuousMonitoringCoverageCheck = async (
    data: IContinuousMonitoringCoverageCheckInputData,
    practice: IPractice,
    dispatch: ThunkDispatch<unknown, unknown, any>,
    triesCount = 1
) => {
    const defaultErrorResult = CheckerService.getDefaultErrorCoverageResult(
        data.id,
        data.payer,
        data.memberId,
        data.inNetworkCheck
    )

    try {
        // run here 2 checks if 2 checks were requested: inNetwork and outOfNetwork
        for (const networkType of ["inNetworkCheck", "outNetworkCheck"]) {
            if (!data[networkType]) {
                continue
            }

            const needRetry = triesCount < CHECKER_CONFIG.maxGetEstimateRetry

            const result =
                await CheckerService.runContinuousMonitoringCoverageCheck(
                    data,
                    practice,
                    needRetry,
                    networkType === "inNetworkCheck"
                )

            if (!result && needRetry) {
                return await runContinuousMonitoringCoverageCheck(
                    data,
                    practice,
                    dispatch,
                    triesCount + 1
                )
            }

            dispatch(
                checkerReceivedContinuousMonitoringCoverageResult(
                    result || defaultErrorResult
                )
            )
        }
    } catch (e) {
        // If errored -> set default error result
        dispatch(
            checkerReceivedContinuousMonitoringCoverageResult(
                defaultErrorResult
            )
        )
    }
}

/**
 * Run single coverage check for medicaid checker
 */
const runMedicaidCoverageCheck = async (
    data: IMedicaidCheckerInputData,
    practice: IPractice,
    dispatch: ThunkDispatch<unknown, unknown, any>,
    triesCount = 1
) => {
    const defaultErrorResult = CheckerService.getDefaultErrorCoverageResult(
        data.id,
        null,
        undefined,
        true
    )

    try {
        const needRetry = triesCount < CHECKER_CONFIG.maxGetEstimateRetry

        const result = await CheckerService.runMedicaidCoverageCheck(
            data,
            practice,
            needRetry
        )

        if (!result && needRetry) {
            return await runMedicaidCoverageCheck(
                data,
                practice,
                dispatch,
                triesCount + 1
            )
        }

        dispatch(
            checkerReceivedMedicaidCoverageResult(result || defaultErrorResult)
        )
    } catch (e) {
        // If errored -> set default error result
        dispatch(checkerReceivedMedicaidCoverageResult(defaultErrorResult))
    }
}
