/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { matchPath, Route, Routes, useLocation } from "react-router-dom"
import TagManager from "react-gtm-module"
import {
    UserIdleTimerElement,
    BrowserStorageHelper,
    BrowserStorageType,
} from "nirvana-react-elements"
import { datadogRum } from "@datadog/browser-rum"

import { PrivateRoute } from "../router"
import { ROUTES_CONFIG, ROUTES_FULL_SCREEN_WIDTH } from "config/routes.config"
import { userDetailsGetProfile } from "../store/thunks/userDetails.thunks"
import { SocketsHelper } from "../helpers/sockets.helper"
import { AuthHelper } from "../helpers/auth.helper"
import { GENERAL_CONFIG } from "../config/general.config"
import { useAppSelector } from "../store/selectors/app.selector"
import { userDetailsSelector } from "../store/selectors/userDetails.selector"
import { userDetailsSetSelectedPracticeRole } from "../store/slices/userDetails.slice"
import { useAppDispatch } from "../store/appDispatch.hook"
import { runtimeSelector } from "../store/selectors/runtime.selector"
import { RuntimeHelper } from "../helpers/runtime.helper"
import { runtimeGetEnabledFeatureFlags } from "../store/thunks/runtime.thunks"

// ROUTES
import { NotFoundComponent } from "../components/general/notFound.component"
import { ProfileComponent } from "../components/profile/profile.component"
import { CoverageCheckerComponent } from "../components/coverageChecker/coverageChecker.component"
import { SupportComponent } from "../components/support/support.component"
import { OrganizationComponent } from "../components/organization/organization.component"
import { SearchPoliciesComponent } from "../components/policies/search/searchPolicies.component"
import { ReportsComponent } from "../components/policies/reports/reports.component"
import { SinglePolicyHistoryComponent } from "../components/policies/singlePolicy/singlePolicyHistory.component"
import { SinglePolicyReportsComponent } from "../components/policies/singlePolicy/singlePolicyReports.component"
import { OverridesComponent } from "../components/policies/overrides/overrides.component"
import { SinglePolicyOverrideComponent } from "../components/policies/singlePolicy/singlePolicyOverride.component"
import { PlanYearResetsComponent } from "../components/policies/planYearResets/planYearResets.component"
import { SinglePolicyPlanYearResetsComponent } from "../components/policies/singlePolicy/singlePolicyPlanYearResets.component"
import { MedicaidReportsComponent } from "components/policies/medicaidReports/medicaidReports.component"
import { SinglePolicyMedicaidReportsComponent } from "../components/policies/singlePolicy/singlePolicyMedicaidReports.component"

export const AppContainer: React.FunctionComponent<
    IContainerComponentProps
> = ({ isLoggedIn }) => {
    const dispatch = useAppDispatch()
    const location = useLocation()

    const userDetailsState = useAppSelector(userDetailsSelector)
    const runtimeState = useAppSelector(runtimeSelector)

    const [previousIsLoggedIn, setPreviousIsLoggedIn] = useState<boolean>(false)

    const isBulkCSVLoading = useMemo<boolean>(() => {
        return RuntimeHelper.isBulkCoverageChecksLoading()
    }, [runtimeState.isLoading])

    const isExportCSVLoading = useMemo<boolean>(() => {
        return RuntimeHelper.isPoliciesExportLoading()
    }, [runtimeState.isLoading])

    useEffect(() => {
        // Make sure to call this just ones
        if (
            isLoggedIn &&
            isLoggedIn !== previousIsLoggedIn &&
            window.location.pathname !== ROUTES_CONFIG.logoutUrl
        ) {
            dispatch(userDetailsGetProfile())

            setPreviousIsLoggedIn(isLoggedIn)
        }
    }, [isLoggedIn])

    // Once got user profile - set user id in Google Analytics for better tracking
    useEffect(() => {
        // Set client portal id when logged in, reset when logged out
        TagManager.dataLayer({
            dataLayer: {
                event: "setUserId",
                userId: userDetailsState.profile?.id || null,
            },
        })

        if (userDetailsState.profile) {
            datadogRum.setUser({
                id: userDetailsState.profile.id.toString(),
                email: userDetailsState.profile.email,
            })
        } else {
            datadogRum.clearUser()
        }
    }, [userDetailsState.profile])

    // Once got user profile - connect our user to sockets
    // Only happens once user finishes onboarding
    useEffect(() => {
        if (!userDetailsState.profile) {
            return
        }

        const socketsInstance = SocketsHelper.getInstance()

        // Make sure to reconnect only in case it's not connected right now
        if (!socketsInstance.isConnected()) {
            socketsInstance.reconnect()
        }
    }, [userDetailsState.profile])

    useEffect(() => {
        if (!userDetailsState.profile?.practiceRoles.length) {
            return
        }

        const eagleEyeData = AuthHelper.getEagleEyeViewAsData()

        // By default, once we get profile details and selectedPracticeRole are not set yet
        // Then during page reload check localStorage storage, if not set there, select [0]
        // IF we already have practiceRole set, but we've received profile, update existing practiceRole
        // Local storage so it persists across tabs
        // Give priority to practice selected with Eagle Eye
        const preSelectedPracticeId =
            userDetailsState.selectedPracticeRole?.practice.id ||
            eagleEyeData?.practice.id ||
            BrowserStorageHelper.get(
                GENERAL_CONFIG.browserStorageKeys.selectedPracticeId,
                undefined,
                BrowserStorageType.localStorage
            )

        const neededPracticeRole = preSelectedPracticeId
            ? userDetailsState.profile.practiceRoles.find(
                  item => item.practice.id === preSelectedPracticeId
              )
            : undefined

        dispatch(
            userDetailsSetSelectedPracticeRole(
                neededPracticeRole || userDetailsState.profile.practiceRoles[0]
            )
        )
    }, [userDetailsState.profile])

    // Monitor change of selected practice and fetch enabled feature flags
    useEffect(() => {
        if (!userDetailsState.selectedPracticeRole?.practice) {
            return
        }

        dispatch(
            runtimeGetEnabledFeatureFlags({
                practice: userDetailsState.selectedPracticeRole.practice,
                payload: undefined,
            })
        )
    }, [userDetailsState.selectedPracticeRole?.practice.id])

    const isFullWidthScreen = useMemo<boolean>(() => {
        return ROUTES_FULL_SCREEN_WIDTH.some(item => {
            return !!matchPath(item, location.pathname)
        })
    }, [location.pathname])

    return (
        <div
            className={`
                ${
                    !isFullWidthScreen
                        ? "mr-45px ml-45px mb-135px sm:mr-16px sm:ml-16px"
                        : ""
                }
            `}
        >
            <Routes>
                <Route
                    path={ROUTES_CONFIG.profileUrl}
                    element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <ProfileComponent />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={ROUTES_CONFIG.coverageCheckerUrl}
                    element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <CoverageCheckerComponent />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={ROUTES_CONFIG.searchUrl}
                    element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <SearchPoliciesComponent />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={ROUTES_CONFIG.searchSinglePolicyUrl}
                    element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <SinglePolicyHistoryComponent />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={ROUTES_CONFIG.reportsUrl}
                    element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <ReportsComponent />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={ROUTES_CONFIG.reportsSinglePolicyUrl}
                    element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <SinglePolicyReportsComponent />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={ROUTES_CONFIG.medicaidReportsUrl}
                    element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <MedicaidReportsComponent />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={ROUTES_CONFIG.medicaidReportsSinglePolicyUrl}
                    element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <SinglePolicyMedicaidReportsComponent />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={ROUTES_CONFIG.overridesUrl}
                    element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <OverridesComponent />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={ROUTES_CONFIG.overridesSinglePolicyUrl}
                    element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <SinglePolicyOverrideComponent />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={ROUTES_CONFIG.planYearResetsUrl}
                    element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <PlanYearResetsComponent />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={ROUTES_CONFIG.planYearResetsSinglePolicyUrl}
                    element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <SinglePolicyPlanYearResetsComponent />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={ROUTES_CONFIG.organizationUrl}
                    element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <OrganizationComponent />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={ROUTES_CONFIG.supportUrl}
                    element={
                        <PrivateRoute isLoggedIn={isLoggedIn}>
                            <SupportComponent />
                        </PrivateRoute>
                    }
                />

                <Route path="*" element={<NotFoundComponent />} />
            </Routes>

            <UserIdleTimerElement
                isActive={isLoggedIn}
                withPrompt
                idleTimeout={15 * 60}
                promptBeforeIdleTimeout={59}
                onIdle={() => {
                    // Don't logout if bulk csv is running
                    // Or if exporting list of policies (from reports for example)
                    if (isBulkCSVLoading || isExportCSVLoading) {
                        return
                    }

                    AuthHelper.logout()
                }}
            />
        </div>
    )
}
