/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import {
    ButtonElement,
    PrimaryText,
    TooltipElement,
} from "nirvana-react-elements"

import {
    BulkOverrideTab,
    OVERRIDES_CONFIG,
} from "../../../../config/overrides.config"
import { useAppDispatch } from "../../../../store/appDispatch.hook"
import { useAppSelector } from "../../../../store/selectors/app.selector"
import { runtimeSelector } from "../../../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../../../helpers/runtime.helper"
import { policiesSelector } from "../../../../store/selectors/policies.selector"
import { selectedPracticeRoleSelector } from "../../../../store/selectors/selectedPracticeRole.selector"
import { policiesGetFullList } from "../../../../store/thunks/policies.thunks"
import { PoliciesViewType } from "../../../../config/policies.config"
import { GENERAL_CONFIG } from "../../../../config/general.config"
import { BulkOverridesPreviewComponent } from "./bulkOverridesPreview.component"
import { BulkOverridesFormComponent } from "./bulkOverridesForm.component"
import { TOOLTIPS_CONFIG } from "../../../../config/tooltips.config"

import closeIcon from "../../../../assets/images/icons/close-dark.svg"
import leftArrowIcon from "../../../../assets/images/icons/arrow-left-dark.svg"

export const BulkOverridesComponent: React.FunctionComponent<
    IBulkOverridesComponentProps
> = props => {
    const dispatch = useAppDispatch()

    const runtimeState = useAppSelector(runtimeSelector)
    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)

    const {
        policiesGettingFullListProgress,
        reportsPagination,
        historyPagination,
        overridesPagination,
        planYearResetsPagination,
        medicaidPagination,
    } = useAppSelector(policiesSelector)

    const [isOverrideFormVisible, setIsOverrideFormVisible] =
        useState<boolean>(false)

    const [isCreationActive, setIsCreationActive] = useState<boolean>(false)
    const [performedSelectAll, setPerformedSelectAll] = useState<boolean>(false)

    const isLoading = useMemo<boolean>(() => {
        return RuntimeHelper.isGettingFullPoliciesListLoading()
    }, [runtimeState.isLoading])

    const typeBasedPagination = useMemo<IPagination>(() => {
        switch (props.coverageChecksType) {
            case PoliciesViewType.REPORTS:
                return reportsPagination

            case PoliciesViewType.HISTORY_SEARCH:
                return historyPagination

            case PoliciesViewType.OVERRIDES:
                return overridesPagination

            case PoliciesViewType.PLAN_YEAR_RESETS:
                return planYearResetsPagination

            case PoliciesViewType.MEDICAID:
                return medicaidPagination
        }
    }, [
        props.coverageChecksType,
        reportsPagination,
        historyPagination,
        overridesPagination,
        planYearResetsPagination,
    ])

    const isSelectAllAvailable = useMemo<boolean>(() => {
        return (
            !performedSelectAll &&
            typeBasedPagination.total > props.coverageChecksToOverride.length
        )
    }, [typeBasedPagination, props.coverageChecksToOverride, isLoading])

    // Reset allow all selection when hiding component
    useEffect(() => {
        setPerformedSelectAll(false)
    }, [isCreationActive])

    const onSelectAllCoverageChecks = async () => {
        if (!selectedPracticeRole) {
            return
        }

        setPerformedSelectAll(true)

        const fetchedItems = await dispatch(
            policiesGetFullList({
                practice: selectedPracticeRole.practice,
                payload: {
                    type: props.coverageChecksType,
                },
            })
        ).unwrap()

        props.setCoverageChecksToOverride(
            fetchedItems.filter(
                item =>
                    item.coverageResult.responseStatus &&
                    OVERRIDES_CONFIG.bulkOverrides.allowedResponseStatusCodes.includes(
                        item.coverageResult.responseStatus
                    )
            )
        )
    }

    // Show nothing if no policies selected for override
    // Trigger button
    return !props.coverageChecksToOverride.length ? null : !isCreationActive ? (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            <div
                className="
                    fixed bottom-16px horizontal-center flex items-center
                    bg-brand-white rounded-8px p-16px shadow-7
                "
            >
                <ButtonElement
                    label={`Bulk Override${
                        isLoading
                            ? ` (${policiesGettingFullListProgress}%)`
                            : ""
                    }`}
                    onClick={() => {
                        setIsCreationActive(true)
                    }}
                    htmlType="button"
                    type="primary"
                    isLoading={isLoading}
                />

                {!isLoading && (
                    <>
                        <PrimaryText
                            className="ml-16px mr-8px"
                            typography="text"
                        >
                            {props.coverageChecksToOverride.length} selected
                        </PrimaryText>

                        {isSelectAllAvailable && (
                            <div
                                className="cursor-pointer ml-8px mr-16px flex items-center"
                                onClick={onSelectAllCoverageChecks}
                            >
                                <PrimaryText typography="textBold" underline>
                                    Select all {typeBasedPagination.total}{" "}
                                    policies
                                </PrimaryText>

                                <TooltipElement
                                    text={
                                        TOOLTIPS_CONFIG.bulkOverrides
                                            .eligibleForBulkOverridePolicies
                                    }
                                    className="ml-8px"
                                />
                            </div>
                        )}

                        <div
                            className="cursor-pointer p-8px relative top--1px"
                            onClick={props.onCancelBulkOverride}
                        >
                            <img src={closeIcon} alt="cancel" />
                        </div>
                    </>
                )}
            </div>
        </div>
    ) : (
        // Actual override table and form
        <div
            className="
                no-body-scroll fixed z-30 left-0 bottom-0 w-screen
                bg-brand-offWhite overflow-y-auto
            "
            style={{
                height: `calc(100vh - ${GENERAL_CONFIG.headerHeight}px)`,
            }}
        >
            <div
                className="
                    px-8px pt-32px pb-8px flex items-center
                    border-b border-solid border-brand-warmLight
                "
            >
                <div
                    className="p-8px mr-8px cursor-pointer"
                    onClick={() => {
                        setIsCreationActive(false)
                    }}
                >
                    <img src={leftArrowIcon} alt="back" />
                </div>

                <PrimaryText typography="h3">Bulk Override</PrimaryText>
            </div>

            <div className="py-24px">
                <BulkOverridesPreviewComponent
                    coverageChecks={props.coverageChecksToOverride}
                    onPreviewTabChange={newTab => {
                        setIsOverrideFormVisible(
                            newTab === BulkOverrideTab.allowed
                        )
                    }}
                />

                {isOverrideFormVisible ? (
                    <BulkOverridesFormComponent
                        className="px-46px mt-16px"
                        coverageChecks={props.coverageChecksToOverride}
                        onDone={props.onCancelBulkOverride}
                        coverageChecksType={props.coverageChecksType}
                        benefitStructureNotRequired={
                            props.benefitStructureNotRequired
                        }
                    />
                ) : null}
            </div>
        </div>
    )
}
