/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react"
import {
    ButtonElement,
    PageHelmetElement,
    useScrollFix,
} from "nirvana-react-elements"
import { useNavigate, useSearchParams } from "react-router-dom"

import {
    AvailableCoveragePortalPermission,
    PermissionsExistenceLogicalOperator,
} from "../../../config/rolesPermissions.config"
import {
    PoliciesViewType,
    PolicyHeaderDragDirection,
} from "../../../config/policies.config"
import {
    FEATURE_FLAGS_CONFIG,
    FeatureFlagsExistenceLogicalOperator,
} from "../../../config/featureFlags.config"
import { useHasPermissions } from "../../../hooks/hasPermissions.hook"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { selectedPracticeRoleSelector } from "../../../store/selectors/selectedPracticeRole.selector"
import { PoliciesListComponent } from "../policiesList/policiesList.component"
import { BrowserStorageHelper } from "../../../helpers/browserStorageHelper"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { GENERAL_CONFIG } from "../../../config/general.config"
import { policiesSetMedicaidFilters } from "../../../store/slices/policies.slice"
import { PoliciesColumnsManagementComponent } from "../policiesList/policiesColumnsManagement.component"
import { PoliciesFilterPopupComponent } from "../../popups/policiesFilterPopup.component"
import { SearchFilterDisplayComponent } from "../search/searchFilterDisplay.component"
import { policiesSelector } from "../../../store/selectors/policies.selector"
import { policiesExportList } from "../../../store/thunks/policies.thunks"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { PoliciesHelper } from "../../../helpers/policies.helper"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { useScrollableElementBoundaries } from "../../../hooks/scrollableElementBoundaries.hook"
import { PoliciesSavedConfigurationsComponent } from "../policiesList/policiesSavedConfigurations.component"
import { usePoliciesList } from "../../../hooks/policiesList.hook"
import { useIsFeatureFlagEnabled } from "../../../hooks/isFeatureFlagEnabled.hook"

import filtersIcon from "../../../assets/images/icons/filters-dark.svg"
import exportIcon from "../../../assets/images/icons/download-up-dark.svg"

export const MedicaidReportsComponent: React.FunctionComponent = () => {
    useScrollFix()

    useHasPermissions(
        [AvailableCoveragePortalPermission.viewHistoricalCoverageChecks],
        PermissionsExistenceLogicalOperator.OR
    )

    useIsFeatureFlagEnabled(
        FEATURE_FLAGS_CONFIG.medicaidReportsFeatureFlags,
        FeatureFlagsExistenceLogicalOperator.OR,
        ROUTES_CONFIG.profileUrl
    )

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [searchParams, setSearchParams] = useSearchParams()

    const {
        // vars
        isFlagsResolutionEnabled,
        isOverridesEnabled,
        canManageFlags,
        canManageOverrides,
        // state
        selectedPolicies,
        setSelectedPolicies,
        // functions
        onPoliciesSelected,
        onPoliciesDeselected,
    } = usePoliciesList()

    const scrollableTableRef = useRef<HTMLDivElement>(null)
    const scrollableTableBoundaries =
        useScrollableElementBoundaries(scrollableTableRef)

    const selectedPracticeRole = useAppSelector(selectedPracticeRoleSelector)
    const runtimeState = useAppSelector(runtimeSelector)

    const { medicaidItems, medicaidFilters, policiesGettingFullListProgress } =
        useAppSelector(policiesSelector)

    const [isFiltersPopupActive, setIsFiltersPopupActive] =
        useState<boolean>(false)

    // This component controls them and passes to list for display and header for modifications
    const [columnsConfiguration, setColumnsConfiguration] = useState<
        IPolicyColumnConfiguration[]
    >([])

    const listPoliciesSelectable = useMemo<boolean>(() => {
        // TODO uncomment below once we implement overrides for these policies or bulk re-run
        return false

        // return (
        //     (canManageFlags && isFlagsResolutionEnabled) ||
        //     (canManageOverrides && isOverridesEnabled)
        // )
    }, [
        isFlagsResolutionEnabled,
        isOverridesEnabled,
        canManageOverrides,
        canManageFlags,
    ])

    const isExportLoading = useMemo<boolean>(() => {
        return RuntimeHelper.isPoliciesExportLoading()
    }, [runtimeState.isLoading])

    // Monitor filters in search params and set them in state
    useEffect(() => {
        const searchParamsFilters = searchParams.get(
            GENERAL_CONFIG.urlSearchParamsKeys.filters
        )

        // If no reports filters by in url (that's our main reports url)
        // Set them in search params so clicking back works fine
        if (!searchParamsFilters) {
            searchParams.set(
                GENERAL_CONFIG.urlSearchParamsKeys.filters,
                JSON.stringify(medicaidFilters)
            )

            setSearchParams(searchParams)

            return
        }

        // In case json is invalid
        try {
            const filtersFromUrl: IPoliciesListFiltersData =
                JSON.parse(searchParamsFilters)

            if (
                JSON.stringify(filtersFromUrl) !==
                JSON.stringify(medicaidFilters)
            ) {
                dispatch(policiesSetMedicaidFilters(filtersFromUrl))
            }
        } catch (e) {}
    }, [searchParams])

    // Change columns configurations once practice role modalities change
    useEffect(() => {
        if (!selectedPracticeRole) {
            return
        }

        setColumnsConfiguration(
            BrowserStorageHelper.getDefaultMedicaidReportsPoliciesColumnsConfiguration(
                selectedPracticeRole.availableModalities
            )
        )
    }, [selectedPracticeRole?.availableModalities])

    const onEditFilters = () => {
        setIsFiltersPopupActive(true)
    }

    const onFiltersSubmitted = (data: IPoliciesListFiltersData) => {
        // Deselect everything on filters change
        setSelectedPolicies([])

        navigate(
            `${ROUTES_CONFIG.medicaidReportsUrl}?${
                GENERAL_CONFIG.urlSearchParamsKeys.filters
            }=${JSON.stringify(data)}`
        )
    }

    const onExportCsv = () => {
        if (!selectedPracticeRole) {
            return
        }

        dispatch(
            policiesExportList({
                practice: selectedPracticeRole.practice,
                payload: {
                    viewType: PoliciesViewType.MEDICAID,
                    columns:
                        BrowserStorageHelper.getDefaultMedicaidReportsPoliciesColumnsConfiguration(
                            selectedPracticeRole.availableModalities
                        ),
                },
            })
        )
    }

    const onSaveColumnsConfiguration = (
        columns: IPolicyColumnConfiguration[]
    ) => {
        setColumnsConfiguration(columns)

        BrowserStorageHelper.saveColumnsConfigurations(
            GENERAL_CONFIG.browserStorageKeys
                .reportsPoliciesColumnsConfiguration,
            columns,
            selectedPracticeRole?.availableModalities
        )
    }

    const onChangeColumnOrder = (
        columnLabelMoveSource: string,
        columnLabelMoveTarget: string,
        direction: PolicyHeaderDragDirection
    ) => {
        const newColumns = PoliciesHelper.changeSingleColumnOrdering(
            columnsConfiguration,
            columnLabelMoveSource,
            columnLabelMoveTarget,
            direction
        )

        onSaveColumnsConfiguration(newColumns)
    }

    const onSavedConfigurationSelected = (
        configuration: IPoliciesSavedConfiguration
    ) => {
        if (
            JSON.stringify(columnsConfiguration) !==
            JSON.stringify(configuration.columns)
        ) {
            onSaveColumnsConfiguration(configuration.columns)
        }

        const newFilters =
            PoliciesHelper.getRelativeToTodaySavedConfigurationFilters(
                configuration
            )

        if (JSON.stringify(medicaidFilters) !== JSON.stringify(newFilters)) {
            onFiltersSubmitted(newFilters)
        }
    }

    return (
        <div className="pb-32 relative">
            <PageHelmetElement
                title="Reports"
                defaultPageTitle={GENERAL_CONFIG.defaultPageTitle}
            />

            <div
                className="
                    relative p-16px flex items-start
                    md:block
                "
                style={{
                    right: scrollableTableBoundaries?.x,
                }}
            >
                <div className="mr-16px">
                    {!Object.keys(medicaidFilters).filter(
                        key => !!medicaidFilters[key]
                    ).length ? (
                        <ButtonElement
                            label="Filters"
                            onClick={onEditFilters}
                            htmlType="button"
                            type="default"
                            icon={filtersIcon}
                            isRightIcon
                        />
                    ) : (
                        <SearchFilterDisplayComponent
                            className="w-380px"
                            onEditFilters={onEditFilters}
                            existingFilters={medicaidFilters}
                        />
                    )}
                </div>

                <div className="flex-1 mx-16px md:hideen" />

                <div className="flex items-center md:mt-32px md:justify-center">
                    {columnsConfiguration.length && selectedPracticeRole ? (
                        <>
                            <PoliciesColumnsManagementComponent
                                className="ml-16px"
                                initialColumnsConfiguration={PoliciesHelper.getDefaultMedicaidReportsColumns(
                                    selectedPracticeRole.availableModalities
                                )}
                                currentColumnsConfiguration={
                                    columnsConfiguration
                                }
                                onColumnsConfigurationChange={
                                    onSaveColumnsConfiguration
                                }
                                isRightPlacement
                            />

                            <PoliciesSavedConfigurationsComponent
                                className="ml-16px"
                                columns={columnsConfiguration}
                                defaultColumns={BrowserStorageHelper.getDefaultMedicaidReportsPoliciesColumnsConfiguration(
                                    selectedPracticeRole.availableModalities
                                )}
                                filters={medicaidFilters}
                                browserStorageKey={
                                    GENERAL_CONFIG.browserStorageKeys
                                        .medicaidReportsSavedConfigurations
                                }
                                isRightPlacement
                                onSavedConfigurationSelected={
                                    onSavedConfigurationSelected
                                }
                            />
                        </>
                    ) : null}

                    <ButtonElement
                        label={`Export CSV${
                            isExportLoading
                                ? ` (${policiesGettingFullListProgress}%)`
                                : ""
                        }`}
                        className="ml-16px"
                        onClick={onExportCsv}
                        htmlType="button"
                        type="default"
                        icon={exportIcon}
                        isLoading={isExportLoading}
                        disabled={!medicaidItems.length}
                        isRightIcon
                    />
                </div>
            </div>

            {selectedPracticeRole && columnsConfiguration.length ? (
                <PoliciesListComponent
                    scrollableElementRef={scrollableTableRef}
                    viewType={PoliciesViewType.MEDICAID}
                    sortEnabled={true}
                    columns={columnsConfiguration}
                    policiesSelectable={listPoliciesSelectable}
                    selectedItems={selectedPolicies}
                    onSelectedItems={onPoliciesSelected}
                    onDeselectedItems={onPoliciesDeselected}
                    onChangeColumnOrder={onChangeColumnOrder}
                />
            ) : null}

            {isFiltersPopupActive && (
                <PoliciesFilterPopupComponent
                    isActive
                    withFlags={true}
                    withResetBenefitsStatus={false}
                    existingFilters={medicaidFilters}
                    persistenceBrowserStorageKey={
                        GENERAL_CONFIG.browserStorageKeys
                            .medicaidPoliciesFiltersConfiguration
                    }
                    onFiltersSubmitted={onFiltersSubmitted}
                    onDone={() => setIsFiltersPopupActive(false)}
                    viewType={PoliciesViewType.MEDICAID}
                />
            )}
        </div>
    )
}
