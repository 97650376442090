export enum FeatureFlag {
    // Coverage Portal / One Verify - TIER features
    // Backend expands these to the individual features

    // Coverage Portal / One Verify - INDIVIDUAL features
    ONE_VERIFY_REPORTS = "one_verify-reports",
    ONE_VERIFY_REPORTS_VISUALIZATION = "one_verify-reports_visualization",
    ONE_VERIFY_SEARCH = "one_verify-search", // previously ONE_VERIFY_REVIEW = "one_verify-review"
    ONE_VERIFY_OVERRIDES = "one_verify-overrides",
    ONE_VERIFY_PLAN_YEAR_RESETS = "one_verify-plan-year-resets",
    ONE_VERIFY_CONTINUOUS_MONITORING_MANUAL_CHECK = "one_verify-continuous_monitoring_manual_check",
    ONE_VERIFY_CONTINUOUS_MONITORING_BULK_CHECKS = "one_verify-continuous_monitoring_bulk_checks",
    ONE_VERIFY_MEDICAID_BULK_CHECKS = "one_verify-medicaid_bulk_checks",
    ONE_VERIFY_MEDICAID_MANUAL_CHECK = "one_verify-medicaid_manual_check",
    ONE_VERIFY_MEDICAID_REPORTS = "one_verify-medicaid_reports",
    ONE_VERIFY_INTAKE_MANUAL_CHECK = "one_verify-intake_manual_check",
    ONE_VERIFY_SMART_SCAN = "one_verify-smart_scan",

    // NOT USED right now fully - archived in flagSmith
    ONE_VERIFY_FLAGS_RESOLUTION = "one_verify-flags_resolution",
    ONE_VERIFY_BILLER_GROUPS = "one_verify-biller_groups",
}

export enum FeatureFlagsExistenceLogicalOperator {
    AND = "AND",
    OR = "OR",
}

export const FEATURE_FLAGS_CONFIG = {
    get reportsFeatureFlags(): FeatureFlag[] {
        return [FeatureFlag.ONE_VERIFY_REPORTS]
    },

    get medicaidReportsFeatureFlags(): FeatureFlag[] {
        return [FeatureFlag.ONE_VERIFY_MEDICAID_REPORTS]
    },

    get bulkCheckerFeatureFlags(): FeatureFlag[] {
        return [
            FeatureFlag.ONE_VERIFY_CONTINUOUS_MONITORING_BULK_CHECKS,
            FeatureFlag.ONE_VERIFY_MEDICAID_BULK_CHECKS,
        ]
    },

    get manualCheckerFeatureFlags(): FeatureFlag[] {
        return [
            FeatureFlag.ONE_VERIFY_INTAKE_MANUAL_CHECK,
            FeatureFlag.ONE_VERIFY_CONTINUOUS_MONITORING_MANUAL_CHECK,
            FeatureFlag.ONE_VERIFY_MEDICAID_MANUAL_CHECK,
        ]
    },

    get checkerFeatureFlags(): FeatureFlag[] {
        return [
            ...FEATURE_FLAGS_CONFIG.bulkCheckerFeatureFlags,
            ...FEATURE_FLAGS_CONFIG.manualCheckerFeatureFlags,
        ]
    },
}
