/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react"
import { NIRVANA_COLORS, PrimaryText } from "nirvana-react-elements"

import {
    CHECKER_CONFIG,
    CoverageCheckerManualCheckType,
} from "../../../config/checker.config"
import {
    FeatureFlag,
    FeatureFlagsExistenceLogicalOperator,
} from "../../../config/featureFlags.config"
import { useAppSelector } from "../../../store/selectors/app.selector"
import { checkerSelector } from "../../../store/selectors/checker.selector"
import { useAppDispatch } from "../../../store/appDispatch.hook"
import { checkerSetManualCheckType } from "../../../store/slices/checker.slice"
import { runtimeSelector } from "../../../store/selectors/runtime.selector"
import { FeatureFlagsHelper } from "../../../helpers/featureFlags.helper"

export const ManualCheckTypeSelectorComponent: React.FunctionComponent<
    IResultsDownloadComponentProps
> = props => {
    const dispatch = useAppDispatch()

    const runtimeState = useAppSelector(runtimeSelector)
    const { manualCheckType: selectedManualCheckType } =
        useAppSelector(checkerSelector)

    const availableTabs = useMemo<ICheckerManualTypeTab[]>(() => {
        return CHECKER_CONFIG.checkerManualModeTabs.filter(item => {
            if (!runtimeState.enabledFeatureFlags) {
                return false
            }

            return item?.featureFlag
                ? FeatureFlagsHelper.isFeatureFlagEnabled(
                      runtimeState.enabledFeatureFlags,
                      item.featureFlag,
                      item.featureFlagExistenceOperator ||
                          FeatureFlagsExistenceLogicalOperator.OR
                  )
                : true
        })
    }, [runtimeState.enabledFeatureFlags])

    // If only one tab is available, do not show the selector
    // And select that type in state
    useEffect(() => {
        if (!availableTabs.length || !runtimeState.enabledFeatureFlags) {
            return
        }

        let isSelectedTabAllowed = false

        switch (selectedManualCheckType) {
            case CoverageCheckerManualCheckType.CONTINUOUS_MONITORING:
                isSelectedTabAllowed = FeatureFlagsHelper.isFeatureFlagEnabled(
                    runtimeState.enabledFeatureFlags,
                    FeatureFlag.ONE_VERIFY_CONTINUOUS_MONITORING_MANUAL_CHECK
                )

                break

            case CoverageCheckerManualCheckType.INTAKE:
                isSelectedTabAllowed = FeatureFlagsHelper.isFeatureFlagEnabled(
                    runtimeState.enabledFeatureFlags,
                    FeatureFlag.ONE_VERIFY_INTAKE_MANUAL_CHECK
                )

                break

            case CoverageCheckerManualCheckType.MEDICAID:
                isSelectedTabAllowed = FeatureFlagsHelper.isFeatureFlagEnabled(
                    runtimeState.enabledFeatureFlags,
                    FeatureFlag.ONE_VERIFY_MEDICAID_MANUAL_CHECK
                )

                break
        }

        if (!isSelectedTabAllowed) {
            onSelected(availableTabs[0].key)
        }
    }, [
        runtimeState.enabledFeatureFlags,
        availableTabs,
        selectedManualCheckType,
    ])

    const onSelected = (value: CoverageCheckerManualCheckType) => {
        dispatch(checkerSetManualCheckType(value))
    }

    return availableTabs.length > 1 ? (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            <div className="flex items-center">
                <PrimaryText typography="buttonText" className="mr-12px">
                    Check Type:{" "}
                    {props.isReadonly
                        ? availableTabs.find(
                              item => item.key === selectedManualCheckType
                          )?.label
                        : ""}
                </PrimaryText>

                {!props.isReadonly && (
                    <div className="flex-1 flex items-center flex-wrap">
                        {availableTabs.map((tab, index) => {
                            const isSelected =
                                tab.key === selectedManualCheckType

                            return (
                                <div
                                    key={index}
                                    className={`
                                        relative mr-8px px-12px pt-14px pb-10px rounded-6px
                                        ${
                                            isSelected
                                                ? "bg-brand-primary"
                                                : "border border-solid border-brand-warmShadow cursor-pointer"
                                        }
                                    `}
                                    onClick={
                                        !isSelected
                                            ? () => onSelected(tab.key)
                                            : undefined
                                    }
                                >
                                    <PrimaryText
                                        typography="buttonText"
                                        color={
                                            isSelected
                                                ? NIRVANA_COLORS.brand.white
                                                : NIRVANA_COLORS.brand.primary
                                        }
                                    >
                                        {tab.label}
                                    </PrimaryText>

                                    {tab.isBeta ? (
                                        <PrimaryText
                                            typography="captionSemibold"
                                            color={NIRVANA_COLORS.brand.error}
                                            className="absolute z-10 top--8px right--8px bg-brand-offWhite rounded-3px px-4px"
                                        >
                                            BETA
                                        </PrimaryText>
                                    ) : null}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    ) : null
}
